/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const publishDeviceEvent = /* GraphQL */ `
  mutation PublishDeviceEvent(
    $deviceID: String!
    $eventName: String!
    $siteName: String!
    $data: AWSJSON!
  ) {
    publishDeviceEvent(
      deviceID: $deviceID
      eventName: $eventName
      siteName: $siteName
      data: $data
    ) {
      deviceID
      eventName
      eventReceivedSuccess
      greenlightProcessTimestampUTC
      greenlightPublishTimestampUTC
      isCancelled
      lastSuccessID
      lastSuccessfulEventTimestampUTC
      lastTestID
      normalizerEventEmployeeLogin
      siteName
      testDurationSeconds
      testEndTimestampUTC
      testStartTimestampUTC
      testSubmittedBy
    }
  }
`;
export const publishEventTest = /* GraphQL */ `
  mutation PublishEventTest($input: GreenlightActionInput!) {
    publishEventTest(input: $input) {
      siteName
      submitted {
        eventName
        deviceID
      }
    }
  }
`;
export const cancelEventTest = /* GraphQL */ `
  mutation CancelEventTest($input: GreenlightActionInput!) {
    cancelEventTest(input: $input) {
      siteName
      cancelled {
        eventName
        deviceID
      }
    }
  }
`;
