/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onDeviceEventPublished = /* GraphQL */ `
  subscription OnDeviceEventPublished($siteName: String!) {
    onDeviceEventPublished(siteName: $siteName) {
      deviceID
      eventName
      eventReceivedSuccess
      greenlightProcessTimestampUTC
      greenlightPublishTimestampUTC
      isCancelled
      lastSuccessID
      lastSuccessfulEventTimestampUTC
      lastTestID
      normalizerEventEmployeeLogin
      siteName
      testDurationSeconds
      testEndTimestampUTC
      testStartTimestampUTC
      testSubmittedBy
    }
  }
`;
export const onEventTestStarted = /* GraphQL */ `
  subscription OnEventTestStarted($siteName: String) {
    onEventTestStarted(siteName: $siteName) {
      siteName
      submitted {
        eventName
        deviceID
      }
    }
  }
`;
export const onEventTestCancelled = /* GraphQL */ `
  subscription OnEventTestCancelled($siteName: String!) {
    onEventTestCancelled(siteName: $siteName) {
      siteName
      cancelled {
        eventName
        deviceID
      }
    }
  }
`;
