/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const callPACS = /* GraphQL */ `
  query CallPACS($pacsMethod: String!, $parameters: AWSJSON) {
    callPACS(pacsMethod: $pacsMethod, parameters: $parameters) {
      response
    }
  }
`;
export const getSiteEvents = /* GraphQL */ `
  query GetSiteEvents($siteName: String!) {
    getSiteEvents(siteName: $siteName)
  }
`;
export const getKeepSiteDevices = /* GraphQL */ `
  query GetKeepSiteDevices($input: GreenlightActionInput!) {
    getKeepSiteDevices(input: $input) {
      siteName
      data
    }
  }
`;
