import React, { useEffect, useState } from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useBundle } from '@amzn/react-arb-tools';
import Select, { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Link from '@amzn/awsui-components-react/polaris/link';
import { API } from 'aws-amplify';
import { graphqlOperation } from '@aws-amplify/api';
import { callPACS } from '../graphql/queries';
import { useHookstate } from '@hookstate/core';
import { appBaseState } from 'src/stores/app';
import { APP_BASE_TITLE, NAV_LINKS } from 'src/constants';
import { Spinner } from '@amzn/awsui-components-react';


interface SiteSelectionProps {
    isVisible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

function SiteNavigation({ isVisible, setVisible }: SiteSelectionProps) {
    const [canClose, setCanClose] = useState(true);
    const [canAccept, setCanAccept] = useState(false);
    const [pacsSites, setPacsSites] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedSite, setSelectedSite] = useState<SelectProps.Option | null>(null);
    const [bundle, isBundleLoading] = useBundle('components.App');
    const appState = useHookstate(appBaseState);

    let navigate = useNavigate();
    let location = useLocation();
    let { siteName } = useParams();

    // set our page title
    useEffect(() => {
        document.title = `${APP_BASE_TITLE} ${appState.stage.value === 'prod' ? '' : `:: ${appState.stage.value.toUpperCase()}`} ${siteName ? `- ${siteName.toUpperCase()}` : ``}`;
    }, [location]);

    useEffect(() => {
        let isMounted = true;

        const fetchSites = async () => {
            return await API.graphql(
                graphqlOperation(callPACS, {
                    pacsMethod: 'getSites'
                })
            )
        }

        fetchSites().then(res => {
            //@ts-expect-error
            const pacsSites = JSON.parse(res.data.callPACS.response);
            appState.loadedSites.set(pacsSites);
            
            let siteList = new Array();

            // @ts-expect-error
            pacsSites.forEach(pacsSite => {
                siteList.push({
                    label: pacsSite.SiteCode,
                    value: pacsSite.SiteCode,
                })
            });

            if (isMounted) {
                //@ts-expect-error
                setPacsSites(siteList);
                setIsLoading(false);
            }
        }).catch((err) => {
            console.error(`Received error, ${err}`);
        });

        return () => { isMounted = false };
    }, []);

    useEffect(() => {
        const path = document.location.pathname;

        if (path === '/') {
            setVisible(true);
            setCanClose(false);
        } else if (path.startsWith('/site/')) {
            const site = path.split('/')[2];
            setSelectedSite({ value: site, label: site.toUpperCase() });
            setCanClose(true);
            setVisible(false);
        }
    }, [location]);

    function handleOkay() {
        setVisible(false);
        return navigate(`/site/${selectedSite?.value!.toUpperCase()}`);
    }

    if (isBundleLoading) return (<Spinner size='large'/>);

    return (
        <Modal
            visible={isVisible}
            onDismiss={() => {
                if (canClose)
                    setVisible(false)
            }}
            header={bundle.getMessage('select-site')}
            footer={
                <>
                    <Box float='left'>
                        <Link external externalIconAriaLabel='Opens in a new tab' href={NAV_LINKS.GREENLIGHT_PUBLIC_WIKI}>
                            {bundle.getMessage('help')}
                        </Link>
                    </Box>
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button
                                variant='link'
                                disabled={!canClose}
                                onClick={() => setVisible(false)}
                            >
                                {bundle.getMessage('cancel')}
                            </Button>
                            <Button 
                                variant='primary'
                                onClick={() => handleOkay()}
                                disabled={selectedSite === null ? true : false}
                            >
                                Ok
                            </Button>
                        </SpaceBetween>
                    </Box>
                </>
            }
        >
            <Select
                selectedOption={selectedSite}
                onChange={({ detail }) => {
                    setSelectedSite(detail.selectedOption);
                }}
                filteringType='auto'
                filteringPlaceholder={bundle.getMessage('filter-for-site')}
                statusType={isLoading ? 'loading' : 'finished'}
                options={pacsSites}
                placeholder={bundle.getMessage('select-site')}
                selectedAriaLabel="Selected"
                empty="No options"
                loadingText={bundle.getMessage('waiting-for-site-data')}
            />
        </Modal> 
    )
}

export default SiteNavigation;