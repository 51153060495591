import React, { ReactNode, useEffect, useState } from 'react';
import { Alert, Box, Button, FlashbarProps, FormField, Modal, Multiselect, MultiselectProps, RadioGroup, SpaceBetween, TableProps } from "@amzn/awsui-components-react";
import { EventsTableItem, GreenlightActionInput } from "src/interfaces";
import { useParams } from 'react-router-dom';
import { useHookstate } from '@hookstate/core';
import { appBaseState } from 'src/stores/app';
import { NotificationActionType, useNotifications } from 'src/hooks/notifications';
import { cancelEventTest, publishEventTest } from 'src/graphql/mutations';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import * as APIt from 'src/API';
import { GreenlightTestConditions } from 'src/types';

interface BulkActionProps {
    deviceItems: any;
    eventColumns: any;
    action: string;

    isVisible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export function BulkModal({ deviceItems, eventColumns, action, isVisible, setVisible }: BulkActionProps) {
    const appState = useHookstate(appBaseState);
    const { dispatch } = useNotifications();

    const [canClose, setCanClose] = useState(true);
    const [actionSubmitting, setActionSubmitting] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [modalHeader, setModalHeader] = useState('Submit bulk action');
    const [testCondition, setTestCondition] = useState<GreenlightTestConditions>('UNTESTED');
    const [selectedDevices, setSelectedDevices] = useState<MultiselectProps.Options>([]);
    const [deviceOptions, setDeviceOptions] = useState<MultiselectProps.Option[]>([]);
    const [selectedEvents, setSelectedEvents] = useState<MultiselectProps.Options>([]);
    const [eventOptions, setEventOptions] = useState<MultiselectProps.Option[]>([]);

    let { siteName } = useParams();

    useEffect(() => {
        switch (action) {
            case "cancel": {
                setModalHeader("Cancel tests");
                break;
            }
            case "test": {
                setModalHeader("Submit tests");
                break;
            }
        }

        const filteredCols = eventColumns.filter((col: TableProps.ColumnDefinition<any>) => !['device_name', 'parent_device_name', 'Normal Exit REX'].includes(col.id!));

        const devices = deviceItems.map((device: EventsTableItem) => ({
            label: device.device_name,
            value: device.device_id
        }));

        const events = filteredCols.map((event: TableProps.ColumnDefinition<any>) => ({
            label: event.header,
            value: event.header
        }));
        
        setDeviceOptions(devices);
        setSelectedDevices(devices);
        setEventOptions(events);
        setSelectedEvents(events);

    }, [action]);

    const handleSubmit = async () => {
        if (!siteName || !appState.user.value.username) {
            let message: FlashbarProps.MessageDefinition = {
                type: 'error',
                header: 'Failed to submit bulk action',
                content: 'There was an error when trying to submit the bulk request. Try refreshing the page and resubmitting your request.'
            }

            dispatch({
                type: NotificationActionType.ADD_NOTIFICATION,
                message: message
            });

            setVisible(false);

            return;
        }

        setActionSubmitting(true);

        let data: GreenlightActionInput[] = [];

        selectedDevices.forEach((device: MultiselectProps.Option) => {
            selectedEvents.forEach((event: MultiselectProps.Option) => {
                data.push({
                    deviceID: device.value!,
                    eventName: event.value!,
                    testDuration: action == 'test' ? (event.value!.includes('Held') ? 120 : 60) : undefined,
                    testConditions: action == 'test' ? testCondition : undefined,
                });
            })
        });

        const APIRoute = action == 'test' ? publishEventTest : cancelEventTest;

        try {
            const submitReq = await API.graphql(
                graphqlOperation(APIRoute, {
                    input: {
                        siteName: siteName.toUpperCase(),
                        data: JSON.stringify({
                            submitter: appState.user.value.username,
                            tests: data
                        })
                    }
                })
            ) as GraphQLResult<APIt.PublishEventTestMutation | APIt.CancelEventTestMutation>;

            if (submitReq.errors) {
                console.error(submitReq.errors);

                let message: FlashbarProps.MessageDefinition = {
                    type: 'error',
                    header: 'Unexpected response submitting bulk request',
                    content: 'There was an unexpected response from our API when submitting the bulk request. Try resubmitting your request or refreshing the page.'
                }
    
                dispatch({
                    type: NotificationActionType.ADD_NOTIFICATION,
                    message: message
                });
            } 
        } catch (err) {
            console.error(err);

            let message: FlashbarProps.MessageDefinition = {
                type: 'error',
                header: 'Failed to submit bulk request',
                content: 'There was an issue submitting the bulk request. Try waiting a bit and resubmit your request or refresh the page.'
            }

            dispatch({
                type: NotificationActionType.ADD_NOTIFICATION,
                message: message
            });
        }

        setActionSubmitting(false);
        setVisible(false);
    }

    return (
        <Modal
            onDismiss={() => {
                if (actionSubmitting || selectedDevices.length < 1 || selectedEvents.length < 1) {
                    return;
                }

                setVisible(false)
            }}
            visible={isVisible}
            header={modalHeader}
            size='large'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' loading={actionSubmitting} onClick={() => setVisible(false)}>
                            Cancel
                        </Button>
                        <Button variant='primary' onClick={() => handleSubmit()} loading={actionSubmitting} disabled={!selectedDevices.length || !selectedEvents.length}>
                            Submit
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='l'>
                <FormField 
                    label="Devices" 
                    description="Devices to test or cancel events for."
                    errorText={selectedDevices.length ? '' : 'No device is selected.'}
                >
                    <Multiselect 
                        selectedOptions={selectedDevices}
                        onChange={({ detail }) => {
                            setSelectedDevices(detail.selectedOptions);
                        }}
                        filteringType='auto'
                        options={deviceOptions}
                        placeholder='Select devices'
                        tokenLimit={3}
                        i18nStrings={{
                            tokenLimitShowMore: "Show more selected devices",
                            tokenLimitShowFewer: "Show fewer selected devices"
                        }}
                    />
                </FormField>
                <FormField 
                    label="Events" 
                    description="Events to test or cancel."
                    errorText={selectedEvents.length ? '' : 'No event is selected.'}
                >
                    <Multiselect 
                        selectedOptions={selectedEvents}
                        onChange={({ detail }) => {
                            setSelectedEvents(detail.selectedOptions);
                        }}
                        filteringType='auto'
                        options={eventOptions}
                        placeholder='Select events'
                        tokenLimit={3}
                        i18nStrings={{
                            tokenLimitShowMore: "Show more selected events",
                            tokenLimitShowFewer: "Show fewer selected events"
                        }}
                    />
                </FormField>
                    {action == 'cancel' ? (
                        <Alert header="Cancelling events">
                            Requesting a cancellation will forcefully cancel every active test matching 
                            your device and event selections. Tests will only be cancelled if they are still 
                            active at the time that Greenlight tries to make the change.
                        </Alert>
                    ) : (
                        <FormField 
                            label="Conditions" 
                            description="Test conditions"
                        >
                            <RadioGroup
                                onChange={({ detail }) => {
                                    setTestCondition(detail.value as GreenlightTestConditions)
                                }}
                                value={testCondition}
                                items={[
                                    {
                                        value: "UNTESTED",
                                        label: "Untested",
                                        description: "Only submit tests for events if they have not been tested before."
                                    },
                                    {
                                        value: "FAILED",
                                        label: "Failed",
                                        description: "Only submit tests for failed events."
                                    },
                                    {
                                        value: "RETEST_SUCCESS",
                                        label: "Retest",
                                        description: "Resubmit tests for events that have a success."
                                    },
                                    {
                                        value: "ALL",
                                        label: "All",
                                        description: "Submit a test for each device and event regardless of their current test result. This will overwrite any previous test that has been submitted for a device."
                                    }
                                ]}
                            />
                        </FormField>
                    )}
            </SpaceBetween>
        </Modal>
    )
}