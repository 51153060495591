import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { ButtonDropdown } from '@amzn/awsui-components-react';


function PageHeader({exportSiteDevices}: {
    exportSiteDevices: Function
}) {
    let { siteName } = useParams();

    return (
        <Header
            variant='h1'
            actions={
                <SpaceBetween direction='horizontal' size='xs'>
                    <Button iconName='download' disabled={false} onClick={() => exportSiteDevices()}>
                        Export successful devices
                    </Button>
                </SpaceBetween>
            }
        >
            {siteName!.toUpperCase()}
        </Header>
    )
}

export default PageHeader;