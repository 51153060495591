import * as React from 'react';
import { useEffect, useState } from 'react';
import fetch from '@amzn/sentry-fetch';

import Linkify from 'react-linkify';
import Link from '@amzn/awsui-components-react/polaris/link';
import Box from '@amzn/awsui-components-react/polaris/box';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import { AlertProps } from '@amzn/awsui-components-react';

const IS_IT_DOWN_URL = `https://is-it-down.amazon.com/greenlight`;
const IS_IT_DOWN_API = `${IS_IT_DOWN_URL}/components/19884.js`;

const IsItDown = () => {
  const [isItDownResponse, setIsItDownResponse] = useState<IsItDownResponse | null>(null);
  let alertType: AlertProps.Type;

  useEffect(() => {
    fetch(IS_IT_DOWN_API)
      .then((response: Response) => response.json())
      .then((result: IsItDownResponse) => {
        setIsItDownResponse(result);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, []);

  switch (isItDownResponse?.level) {
    case 2: {
      alertType = 'warning';
      break;
    }
    case 3: {
      alertType = 'error';
      break;
    }
    default: alertType = 'info';
  }

  /* is-it-down websites sends an anchor tag text whenever there is a link in the banner content
     Eg, "For more information check <a href="some_link">some_link</a>", the Alert component just displays
     the text with the html tag init. In order to render text as a link we are using removeAnchorTagFromString
     fn and then create a link using Linkify*/
  return (
    isItDownResponse && <div aria-live="assertive" role="alert">
      {
        isItDownResponse.level !== 0 && <Alert
          type={alertType}
          className="awsui"
        >
          <Linkify>{removeAnchorTagFromString(isItDownResponse.message)}</Linkify>.
          <Box><Link external href={IS_IT_DOWN_URL}>Is Greenlight down?</Link></Box>
        </Alert>
      }
    </div>
  );
};

const removeAnchorTagFromString = (input = '') => {
    const startAnchorTag = /(<a\b[^>]*>)/g;
    const endAnchorTag = /(<\/a>)/g;

    let parsedInput = input.replace(startAnchorTag, '');
    parsedInput = parsedInput.replace(endAnchorTag, '');
    
    return parsedInput;
};


type IsItDownResponse = {
    level: number,
    message: string
    name: string
    tickets: string
}

export default IsItDown;