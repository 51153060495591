import React, { Component, useEffect, useState } from 'react';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import PageHeader from './Header';
import { useLocation, useParams } from 'react-router-dom';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from 'src/API';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { callPACS, getKeepSiteDevices, getSiteEvents } from 'src/graphql/queries';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import SiteSummary from './SiteSummary';
import DeviceTests from './DeviceTests';
import { AppLayout, Box, BreadcrumbGroup } from '@amzn/awsui-components-react';
import { useHookstate } from '@hookstate/core';
import { appBaseState, appEventsBaseState } from 'src/stores/app';
import { NotificationActionType, useNotifications } from 'src/hooks/notifications';
import * as subscriptions from 'src/graphql/subscriptions';
import { EventsTableItem, PACSDevice, ReaderConfigData } from 'src/interfaces';
import { SSMClient } from '@aws-sdk/client-ssm';
import { exportDeviceCSV } from 'src/utils/exportDevices';

export default function SitePage() {
    let location = useLocation();
    let { siteName } = useParams();

    const appState = useHookstate(appBaseState);
    const appEventState = useHookstate(appEventsBaseState);
    const { notifications, dispatch } = useNotifications();
    const [siteDevices, setSiteDevices] = useState<any>(null);
    const [siteLinkedDevices, setSiteLinkedDevices] = useState<any>(null);
    const [siteReaderConfigs, setSiteReaderConfigs] = useState<any>(null);
    const [siteEvents, setSiteEvents] = useState<any[]>([]);
    const [siteDevicesLoaded, setSiteDevicesLoaded] = useState(false);

    // Export all devices for the site
    const exportSiteDevices = () => {
        const ucDevices = appEventState.loadedSiteDevices.get({
            noproxy: true
        }).filter(device => (
            device.device_name && device.device_name.startsWith('uc_')
        )) as EventsTableItem[];

        // Return a failure message if there are no devices to export
        if (ucDevices.length < 1) {
            let message: FlashbarProps.MessageDefinition = {
                type: 'error',
                header: 'No items to export',
                content: 'There are no available devices to export for this site. Only uc_ devices can be exported.'
            }

            dispatch({
                type: NotificationActionType.ADD_NOTIFICATION,
                message: message
            });

            return;
        }

        exportDeviceCSV(siteName!, ucDevices);
    }

    const getSiteTrackedEvents = () => {
        Auth.currentCredentials().then(creds => {
            const ssm = new SSMClient({
                region: 'us-east-1',
                credentials: Auth.essentialCredentials(creds)
            });

            let params: string[] = [];

            
        })
    }

    const fetchSiteDevices = async (siteRegion: string | undefined = undefined) => {
        try {
            const siteDevices = await API.graphql(
                graphqlOperation(callPACS, {
                    pacsMethod: 'getSiteDevices',
                    parameters: JSON.stringify({
                        siteName: siteName!.toUpperCase(),
                        region: siteRegion
                    })
                })
            ) as GraphQLResult<APIt.CallPACSQuery>;

            if (siteDevices.errors) {
                console.error(siteDevices.errors);
                return null;
            }

            return siteDevices.data;
        } catch (err) {
            console.error(`Unexpected response when calling AppSync.`, err);
            return null;
        }
    }

    const lookupSiteKeepDevices = async (siteRegion: string = 'AMER', keepSegmentID: number, keepDeviceType: string | null = null) => {
        try {
            const keepLookupReq = await API.graphql(
                graphqlOperation(getKeepSiteDevices, {
                    input: {
                        siteName: siteName!.toUpperCase(),
                        data: JSON.stringify({
                            siteRegion: siteRegion,
                            keepDeviceType: keepDeviceType,
                            keepSegmentID: keepSegmentID
                        })
                    }
                })
            ) as GraphQLResult<APIt.GetKeepSiteDevicesQuery>;

            if (keepLookupReq.errors) {
                console.error(keepLookupReq.errors);
                return null;
            }

            return keepLookupReq.data;
        } catch (err) {
            console.error(`Unexpected response when calling AppSync.`, err);
            return null;
        }
    }

    const fetchSiteLinkedDevices = async (siteRegion: string | undefined = undefined) => {
        try {
            const linkedIODevices = await API.graphql(
                graphqlOperation(callPACS, {
                    pacsMethod: 'getSiteIOLinks',
                    parameters: JSON.stringify({
                        siteName: siteName!.toUpperCase(),
                        region: siteRegion
                    })
                })
            ) as GraphQLResult<APIt.CallPACSQuery>;

            if (linkedIODevices.errors) {
                console.error(linkedIODevices.errors);
                return null;
            }

            return linkedIODevices.data;
        } catch (err) {
            console.error(`Unexpected response when calling AppSync.`, err);
            return null;
        }
    }

    const fetchSiteReaderConfigs = async (siteRegion: string | undefined = undefined) => {
        try {
            const readerConfigData = await API.graphql(
                graphqlOperation(callPACS, {
                    pacsMethod: 'getSiteReaderConfigs',
                    parameters: JSON.stringify({
                        siteName: siteName!.toUpperCase(),
                        region: siteRegion
                    })
                })
            ) as GraphQLResult<APIt.CallPACSQuery>;

            if (readerConfigData.errors) {
                console.error(readerConfigData.errors);
                return null;
            }

            return readerConfigData.data;
        } catch (err) {
            console.error(`Unexpected response when calling AppSync.`, err);
            return null;
        }
    }

    const fetchSiteEvents = async (reload = false) => {
        // Clear loaded device state since table re-sets them.
        appEventState.loadedSiteDevices.set([]);

        try {
            const siteEvents = await API.graphql(
                graphqlOperation(getSiteEvents, {
                    siteName: siteName!.toUpperCase()
                })
            ) as GraphQLResult<APIt.GetSiteEventsQuery>;

            if (siteEvents.errors) {
                console.error(siteEvents.errors);
                return null;
            }

            if (!reload) {
                return siteEvents.data;
            } else {
                try {
                    // @ts-expect-error
                    const events = JSON.parse(siteEvents.data.getSiteEvents);
                    setSiteEvents(events);
                } catch (err) {
                    let message: FlashbarProps.MessageDefinition = {
                        type: 'error',
                        header: 'Failed to update site event',
                        content: 'There was an error refreshing the events for this site. Try refreshing your page to pull the latest events.',
                        dismissible: true
                    }
    
                    dispatch({
                        type: NotificationActionType.ADD_NOTIFICATION,
                        message: message
                    });
    
                    console.error(err);
                }
            }
        } catch (err) {
            console.error(`Unexpected response when calling AppSync.`, err);
            return null;
        }
    }

    const fetchSiteData = async (pacsRegion: string | undefined = undefined) => {
        const siteDevicesFetch = await fetchSiteDevices(pacsRegion);

        let siteDevices;

        if (!siteDevicesFetch || !siteDevicesFetch.callPACS || !siteDevicesFetch.callPACS.response) {
            let message: FlashbarProps.MessageDefinition = {
                type: 'error',
                header: 'Failed to grab site devices',
                content: 'There was an error grabbing the devices for this site. Refresh your page to try again.',
                dismissible: true
            }

            dispatch({
                type: NotificationActionType.ADD_NOTIFICATION,
                message: message
            });

            throw new Error("There was an error fetching the devices from PACS for this site.");
        } else {
            // Parse our site devices and add device ID
            siteDevices = JSON.parse(siteDevicesFetch.callPACS.response).map((device: PACSDevice) => ({
                ...device,
                DeviceID: `${device.Parent_DeviceID}_${device.Child_DeviceID}_${device.Subchild_DeviceID}`
            }));

            setSiteDevices(siteDevices);
        }

        let linkedSiteDevices = null;
        let siteReaderConfigs = null;

        // Look for a Keep device from the PACS device list
        const keepDeviceSearch: (PACSDevice | undefined) = siteDevices.find((device: PACSDevice) => device.devicesource === 'keep');

        // If we have any Keep devices from PACS, perform a lookup from Normalizer
        if (siteDevices != undefined && keepDeviceSearch != undefined) {
            // Use segment ID from Keep device
            const keepSegmentID = keepDeviceSearch.SegmentID;

            // Lookup devices for specified segment ID
            const keepLookup = await lookupSiteKeepDevices(pacsRegion, keepSegmentID);

            if (keepLookup && keepLookup.getKeepSiteDevices?.data) {
                // Parse our response from Keep
                const keepDevices = JSON.parse(keepLookup.getKeepSiteDevices.data);

                // Grab Keep readers. Type is intentionally left open to avoid issues with table structure from Keep
                const keepReaders = keepDevices.filter((keepDevice: any) => keepDevice.object_type.S === 'device_card_reader');

                // Setup configuration for readers based on Keep table data. This will match OnGuard / Lenel devices
                let keepReaderConfigs = keepReaders.map((keepReader: any) => ({
                    objectKey: keepReader.objectKey.S,
                    PANELID: Number(keepReader.PARENT_DEVICE_ID.N),
                    READERID: Number(keepReader.CHILD_DEVICE_ID.N),
                    READERDESC: keepReader.CommonName.S,
                    OPENTIME: Number(keepReader.DoorHeldTime.N),
                    DONOTWAIT: Number(keepReader.AssumeDoorUsed.BOOL),
                    PAIRMASTER: keepReader.ObjectLinks.L.find((link: any) => link.M.Relation.S === 'Slave') ? 1 : 0,
                    PAIRSLAVE: keepReader.ObjectLinks.L.find((link: any) => link.M.Relation.S === 'Reader') ? 1 : 0,
                    REX_SUPERVISION: null,
                    DOORCONTACT_SUPERVISION: null
                }));

                // Grab door contact and REX entries from Keep device response
                const keepReaderDevices = keepDevices.filter((keepDevice: any) => ['card_reader_rex', 'card_reader_door_contact'].includes(keepDevice.object_type.S));

                // Find necessary door properties from reader devices
                keepReaderDevices.forEach((readerDevice: any) => {
                    // Only grab object if it is linked to a reader
                    const readerObject = readerDevice.ObjectLinks.L.find((link: any) => (link.M.Relation.S === 'Reader'));

                    if (!readerObject)
                        return;

                    // Grab the device Key for the linked reader in the object and behaviour value
                    const linkedReaderKey = readerObject.M.LinkedObjectKey.S;
                    const setBehaviour = Number(readerDevice.Behaviour.N);

                    // Add value of behaviour to reader config using device key
                    const readerConfig = keepReaderConfigs.find((config: any) => config.objectKey === linkedReaderKey);

                    if (!readerConfig) {
                        console.warn(`Missing reader config for device`, readerObject);
                        return;
                    }

                    switch (readerDevice.object_type.S) {
                        case 'card_reader_rex':
                            readerConfig.REX_SUPERVISION = setBehaviour;
                            break;

                        case 'card_reader_door_contact':
                            readerConfig.DOORCONTACT_SUPERVISION = setBehaviour;
                            break;
                    
                        default:
                            break;
                    }
                })

                siteReaderConfigs = keepReaderConfigs.length < 1 ? null : keepReaderConfigs;
            }
        } else { 
            // Grab our device configurations and I/O links from PACS
            const siteLinkedDevicesFetch = await fetchSiteLinkedDevices(pacsRegion);
            const siteReaderConfigsFetch = await fetchSiteReaderConfigs(pacsRegion);

            if (siteLinkedDevicesFetch && siteLinkedDevicesFetch.callPACS?.response)
                linkedSiteDevices = JSON.parse(siteLinkedDevicesFetch.callPACS.response) ?? null;

            if (siteReaderConfigsFetch && siteReaderConfigsFetch.callPACS?.response)
                siteReaderConfigs = JSON.parse(siteReaderConfigsFetch.callPACS.response) ?? null;
        }

        setSiteLinkedDevices(linkedSiteDevices);
        setSiteReaderConfigs(siteReaderConfigs);

        const [siteEventsFetch] = await Promise.all([
            fetchSiteEvents()
        ]);

        if (siteEventsFetch == null) {
            let message: FlashbarProps.MessageDefinition = {
                type: 'error',
                header: 'Failed to grab site events',
                content: 'There was an error grabbing previous events for this site. Refresh your page to try again.',
                dismissible: true
            }

            dispatch({
                type: NotificationActionType.ADD_NOTIFICATION,
                message: message
            });

            throw new Error("There was an error fetching events. Check the console for errors.");
        } else {
            const events = siteEventsFetch.getSiteEvents ? JSON.parse(siteEventsFetch.getSiteEvents) : [];
            setSiteEvents(events);
            setSiteDevicesLoaded(true);
        }
    }

    // Setup our event subscription
    useEffect(() => {
        const sub = API.graphql(
            graphqlOperation(subscriptions.onDeviceEventPublished, {
                siteName: siteName!.toUpperCase()
            })
        // @ts-expect-error
        ).subscribe({ 
            next: (data: any) => {
                fetchSiteEvents(true);
            },
            error: (error: any) => {
                console.error(`Subscription error..`, error);

                let message: FlashbarProps.MessageDefinition = {
                    type: 'error',
                    header: 'Subscription error',
                    content: 'There was an error subscribing to device events, or the connection was closed. Refresh the page to try again.',
                    dismissible: true
                }

                dispatch({
                    type: NotificationActionType.ADD_NOTIFICATION,
                    message: message
                });
            }
        });

        return () => sub.unsubscribe();
    }, [location]);

    // Setup our tests subscription
    useEffect(() => {
        const sub = API.graphql({
            query: subscriptions.onEventTestStarted,
            variables: {
                siteName: siteName!.toUpperCase()
            }
        // @ts-expect-error
        }).subscribe({
            next: async (data: any) => {
                fetchSiteEvents(true);
            },
            error: (error: any) => {
                console.error(`Subscription error..`, error);

                let message: FlashbarProps.MessageDefinition = {
                    type: 'error',
                    header: 'Subscription error',
                    content: 'There was an error subscribing to device tests, or the connection was closed. Refresh the page to try again.',
                    dismissible: true
                }

                dispatch({
                    type: NotificationActionType.ADD_NOTIFICATION,
                    message: message
                });
            }
        });

        return () => sub.unsubscribe();
    }, [location]);

    // Setup our cancellation subscription
    useEffect(() => {
        const sub = API.graphql({
            query: subscriptions.onEventTestCancelled,
            variables: {
                siteName: siteName!.toUpperCase()
            }
        // @ts-expect-error
        }).subscribe({
            next: async (data: any) => {
                fetchSiteEvents(true);
            },
            error: (error: any) => {
                console.error(`Subscription error..`, error);

                let message: FlashbarProps.MessageDefinition = {
                    type: 'error',
                    header: 'Subscription error',
                    content: 'There was an error subscribing to device cancellation events, or the connection was closed. Refresh the page to try again.',
                    dismissible: true
                }

                dispatch({
                    type: NotificationActionType.ADD_NOTIFICATION,
                    message: message
                });
            }
        });

        return () => sub.unsubscribe();
    }, [location]);

    useEffect(() => {
        setSiteDevicesLoaded(false);
        setSiteDevices(null);
        setSiteEvents([]);

        // Grab PACS site result from siteName (url) parameter
        const pacsSite = appState.loadedSites.value.find(site => site.SiteCode === siteName!.toUpperCase());
        // Grab site region (AMER, EMEA, APAC) for PACS calls
        const pacsRegion = pacsSite ? pacsSite.Site_Region.toUpperCase() : undefined;

        fetchSiteData(pacsRegion);
    }, [location]);

    return (
        <AppLayout
            navigationHide={true}
            toolsHide={true}
            disableContentPaddings={true}
            contentType='default'
            contentHeader={
                siteDevicesLoaded ? (
                    <PageHeader exportSiteDevices={exportSiteDevices}/>
                ) : (
                    null
                )
            }
            breadcrumbs={
                <BreadcrumbGroup items={[
                    {
                        text: 'Greenlight',
                        href: '/'
                    },
                    {
                        text: 'Sites',
                        href: '#'
                    },
                    {
                        text: `${siteName!.toUpperCase() || ''}`,
                        href: '#'
                    }
                ]}
                />
            }
            content={
                <SpaceBetween size='l'>
                    {siteDevicesLoaded ? (
                        <SpaceBetween size='xs'>
                                <div style={{'paddingLeft': '18%', 'paddingRight': '18%'}}>
                                    <SiteSummary siteDevices={siteDevices} siteEvents={siteEvents}/>
                                </div>
                                <div style={{'paddingLeft': '13%', 'paddingRight': '13%'}}>
                                    <DeviceTests 
                                        siteDevices={siteDevices} 
                                        siteReaderConfigs={siteReaderConfigs} 
                                        linkedSiteDevices={siteLinkedDevices} 
                                        siteEvents={siteEvents}
                                        sections={[
                                            { id: "readers", header: "Readers", device_types: ['card_reader'] },
                                            { id: "inputs_outputs", header: "Inputs and Outputs", device_types: ['input', 'output'] },
                                            { id: "command_keypads", header: "Command Keypad", device_types: ['card_reader'] } // uses card_reader devices but later filtered based on type IDs
                                        ]}
                                    />
                                </div>
                        </SpaceBetween>
                    ) : (
                        <Box textAlign='center'>
                            <Spinner size='large' />
                        </Box>
                    )}
                </SpaceBetween>
            }
            stickyNotifications={true}
            notifications={
                <Flashbar items={notifications.items} />
            }
        />
    )
}