import { FlashbarProps } from '@amzn/awsui-components-react';
import React,{ createContext, useContext, useReducer } from 'react';

const initialState: State = {
    items: []
}

export const NotificationContext = createContext<ContextProps>({
    notifications: initialState
} as ContextProps);

interface NotificationAction<T> {
    type: T;
    message?: any;
}

interface State {
    items: FlashbarProps.MessageDefinition[];
}

interface ContextProps {
    notifications: State;
    dispatch: React.Dispatch<NotificationAction<NotificationActionType>>
}

export enum NotificationActionType {
    ADD_NOTIFICATION = 'ADD_NOTIFICATION',
    DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
}

export const reducer = (state: State, action: NotificationAction<NotificationActionType>): State => {
    switch (action.type) {
        case NotificationActionType.ADD_NOTIFICATION: {
            const items = [...state.items, action.message];

            return {
                ...state,
                items
            };
        }

        case NotificationActionType.DELETE_NOTIFICATION: {
            const items = [...state.items];

            items.splice(action.message, 1);

            return {
                ...state,
                items
            }
        }
    }

    return state;
}

export function useNotifications () {
    const context = useContext(NotificationContext);

    return context;
}

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
    const [notifications, dispatch] = useReducer(reducer, initialState);

    const items = [...notifications.items];
    items.forEach((item, i) => {
        item.dismissible = true;
        item.onDismiss = () => {
            dispatch({ type: NotificationActionType.DELETE_NOTIFICATION, message: i })
        }
    })

    return (
        <NotificationContext.Provider value={{ notifications, dispatch }}>{children}</NotificationContext.Provider>
    );
}