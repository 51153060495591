import React, { useEffect, useState } from 'react';
import { Box, Button, ColumnLayout, FlashbarProps, Link, Popover, SpaceBetween } from '@amzn/awsui-components-react';
import StatusIndicator, {StatusIndicatorProps} from "@amzn/awsui-components-react/polaris/status-indicator";
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { useHookstate } from '@hookstate/core';
import { API } from 'aws-amplify';
import { cancelEventTest, publishEventTest } from 'src/graphql/mutations';
import { NotificationActionType, useNotifications } from 'src/hooks/notifications';
import { GreenlightActionInput } from 'src/interfaces';
import { appBaseState } from 'src/stores/app';
import { EventCellProps } from 'src/types';
import { useParams } from 'react-router-dom';
import * as APIt from 'src/API';
import EventCellTestElement from './EventCellTestElement';

function EventCellInfo({
    trackedEvent,
    deviceName,
    deviceType,
    deviceID,
    linkedDevice,
    readerConfig,
    eventData
}: EventCellProps) {
    let { siteName } = useParams();

    const { dispatch } = useNotifications();
    const appState = useHookstate(appBaseState);
    const [relativeTimestamps, showRelativeTimestamps] = useState(true);
    const [isTesting, setTestState] = useState(false);
    const [eventStatus, setEventStatus] = useState<StatusIndicatorProps.Type>('stopped');
    const [testStatus, setTestStatus] = useState<StatusIndicatorProps.Type>('stopped');
    const [eventText, setEventText] = useState<string | JSX.Element>('Not tested');
    const [testText, setTestText] = useState<string | JSX.Element>('Not tested');
    const [elapsedTestTime, setElapsedTime] = useState(0);

    const setElapsedTestTime = () => {
        const now = new Date().getTime();

        if (eventData.lastTestTS == null || eventData.lastTestEndTS < now || (eventData.lastSuccessID == eventData.lastTestID)) {
            return;
        }

        const elapsed = Math.floor((now - eventData.lastTestTS) / 1000);

        setElapsedTime(elapsed);
    }

    const getEventCellInfo = (state: string | undefined = undefined) => {
        const eventTime = eventData.lastEventTS ? new Date(eventData.lastEventTS) : new Date();

        switch (state) {
            case "MISMATCH_TIMESTAMP": {
                return (
                    `No event received during last test`
                )
            }

            default: {
                return (
                    eventTime.toLocaleString()
                )
            }
        }
    }

    const getTestCellInfo = (state: string | undefined = undefined): string | JSX.Element => {
        const testTime = eventData.lastTestTS ? new Date(eventData.lastTestTS) : new Date();

        switch (state) {
            case "MISMATCH_TIMESTAMP": {
                return (
                    `${eventData.testSubmittedBy}@ ${testTime.toLocaleString()}`
                )
            }

            case "CANCELLED": {
                return (
                    <Popover
                        dismissButton={false}
                        position='top'
                        size='small'
                        triggerType='text'
                        content={
                            <div>
                                The last test was cancelled by <Link external href={`https://phonetool.amazon.com/users/${eventData.testSubmittedBy}`}>{eventData.testSubmittedBy}@</Link>
                            </div>
                        }
                    >
                        {`${eventData.testSubmittedBy}@ ${testTime.toLocaleString()}`}
                    </Popover>
                )
            }

            default: {
                return (
                    `${eventData.testSubmittedBy}@ ${testTime.toLocaleString()}`
                )
            }
        }
    }

    // Sets the test and event states for the cell
    const determineTestStatus = () => {
        const now = new Date().getTime();

        // REX events will not have test IDs and are treated slightly differently
        if (trackedEvent === 'Normal Exit REX') {
            if (eventData.lastTestEndTS > now && !eventData.rexTestFailed) { // Pending test :: Test timestamp is current
                setTestState(true);
                setEventStatus('loading');
                setTestStatus('pending');
                setEventText(`Waiting for event (${elapsedTestTime}s)`);
                setTestText(getTestCellInfo());
            } else if (eventData.lastEventReceived && eventData.lastTestEndTS < now) { // Successful test :: Test timestamp is < now and eventReceived == true
                setTestState(false);
                setEventStatus('success');
                setEventText('REX test successful, no event received');
                setTestStatus('success');
                setTestText(getTestCellInfo());
            } else if (eventData.rexTestFailed) { // Failed test :: REX test result is false
                setTestState(false);
                setEventStatus('error');
                setTestStatus('error');
                setEventText('An event was received during the test window');
                setTestText(getTestCellInfo());
            }
        } else {
            if (eventData.lastTestEndTS > now && !eventData.isCancelled && (eventData.lastTestID != eventData.lastSuccessID)) { // Active test :: Last test end timestamp > now, is not cancelled, and test IDs do not match
                setTestState(true);
                setEventStatus('loading');
                setTestStatus('pending');
                setEventText(`Waiting for event (${elapsedTestTime}s)`);
                setTestText(getTestCellInfo());
            } else if (eventData.lastSuccessID != eventData.lastTestID && eventData.lastSuccessID != null && !eventData.isCancelled) { // Latest test failed :: last success ID != last test ID, and was not cancelled
                setTestState(false);
                setEventStatus('error');
                setTestStatus('error');
                setEventText(getEventCellInfo('MISMATCH_TIMESTAMP'));
                setTestText(getTestCellInfo('MISMATCH_TIMESTAMP'));
            } else if (eventData.lastSuccessID == null && eventData.lastTestID != null) { // Failed, has never passed :: has never had a success, and has been tested
                setTestState(false);
                setEventStatus('error');
                setEventText('No event has been received');
                if (eventData.isCancelled) { // Last test was cancelled, but we still consider it tested
                    setTestStatus('stopped');
                    setTestText(getTestCellInfo('CANCELLED'));
                } else { // Last test was not cancelled, so just a regular failure for the test status
                    setTestStatus('error');
                    setTestText(getTestCellInfo());
                }
            } else if (eventData.lastSuccessID == eventData.lastTestID && eventData.lastSuccessID != null && !eventData.isCancelled) { // Successful test :: last succes ID matches our last test ID, and was not cancelled
                setTestState(false);
                setEventStatus('success');
                setEventText(getEventCellInfo());
                setTestStatus('success');
                setTestText(getTestCellInfo());
            } else if (eventData.isCancelled && eventData.lastSuccessID != null) { // Cancelled tests
                setTestState(false);
                if (eventData.lastSuccessID == eventData.lastTestID) { // Last test was successful before the cancellation
                    setTestStatus('stopped');
                    setTestText(getTestCellInfo('CANCELLED'));
                    setEventStatus('success');
                    setEventText(getEventCellInfo());
                } else { // Test was cancelled, but the test before was not successful, so fallback to a fail + cancelled state
                    setTestStatus('error');
                    setTestText(getTestCellInfo('CANCELLED'));
                    setEventStatus('error');
                    setEventText(getEventCellInfo('MISMATCH_TIMESTAMP'));
                }
            }
        }
    }

    const EventCellNoTestElement = () => {
        return (
            <Box>
                <Box variant='strong'>
                    N/A
                </Box>
            </Box>
        )
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (eventData) {
                setElapsedTestTime();
                determineTestStatus();
            }
        }, 1000);

        return () => clearTimeout(timer);
    });

    // Hide unrelated tests for input devices
    if (deviceType && deviceType!.toLowerCase().includes('input') && !trackedEvent.includes('Alarm'))
        return <EventCellNoTestElement />
    
    // Hide unrelated tests for output devices
    if (deviceType && deviceType!.toLowerCase().includes('output') && !trackedEvent.includes('Relay'))
        return <EventCellNoTestElement />

    // Hide tests based on reader configuration
    if (trackedEvent.includes('REX') && readerConfig === undefined)
        return <EventCellNoTestElement />
    
    if (readerConfig) {
        if (readerConfig.DONOTWAIT === 1 && trackedEvent === 'Access Granted No Entry Made')
            return <EventCellNoTestElement />

        if (Number(readerConfig.DOORCONTACT_SUPERVISION) < 2 && ['Door Forced Open', 'Door Held Open', 'Door Forced Open Canceled', 'Door Held Open Canceled'].includes(trackedEvent))
            return <EventCellNoTestElement />

        if (readerConfig.PAIRSLAVE === 1 && ['Door Held Open', 'Door Forced Open', 'Door Forced Open Canceled', 'Door Held Open Canceled'].includes(trackedEvent))
            return <EventCellNoTestElement />

        if ((readerConfig.PAIRMASTER === 1 || readerConfig.PAIRSLAVE === 1 || Number(readerConfig.REX_SUPERVISION) < 2) && trackedEvent.includes('REX'))
            return <EventCellNoTestElement />
    }
    
    return (
        <EventCellTestElement 
            trackedEvent={trackedEvent}
            eventStatus={eventStatus}
            eventText={eventText}
            testStatus={testStatus}
            testText={testText}
            isTesting={isTesting}
            deviceID={deviceID}
            deviceType={deviceType}
            linkedDevice={linkedDevice}
        />
    )
}

export default EventCellInfo;