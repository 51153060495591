import { Link, Popover, Spinner } from '@amzn/awsui-components-react';
import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useBundle } from '@amzn/react-arb-tools';
import { useHookstate } from '@hookstate/core';
import RelativeTime from '@yaireo/relative-time';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PACSDevice } from 'src/interfaces';
import { appEventsBaseState } from 'src/stores/app';

export default function SiteSum({siteDevices, siteEvents}: {
    siteDevices: PACSDevice[],
    siteEvents: any[]
}) {
    let location = useLocation();
    let { siteName } = useParams();

    const [lastTestUser, setLastTester] = useState('-');
    const [lastTestTime, setLastTestTime] = useState('-');
    const [passedEventsCount, setPassedEventCount] = useState('-');
    const [passedEventsPercent, setPassedEventsPercent] = useState('-');
    const [failedEventsCount, setFailedEventsCount] = useState('-');
    const [siteDeviceCount, setSiteDeviceCount] = useState('-');
    const [bundle, isBundleLoading] = useBundle('components.SitePage.SiteSummary');

    const appEventState = useHookstate(appEventsBaseState);

    const calculateTestNumbers = () => {
        const events = [...siteEvents];
        const now = new Date().getTime();

        const passedEvents = events.filter(ev => ev.eventReceivedSuccess);
        const failedEvents = events.filter(ev => ev.eventReceivedSuccess == null || !ev.eventReceivedSuccess);
        const passedPercent = ((passedEvents.length / siteEvents.length) * 100).toFixed(1);

        setPassedEventCount(`${passedEvents.length}`);
        setFailedEventsCount(`${failedEvents.length}`);
        setPassedEventsPercent(`${passedPercent}%`);
    }

    const findLastTests = () => {
        const events = [...siteEvents];
        events.sort((a, b) => a.testStartTimestampUTC - b.testStartTimestampUTC);

        const lastEvent = events.pop();
        const lastTestTime = new Date(lastEvent.testStartTimestampUTC);
        // const relativeTime = new RelativeTime({ locale: 'en-GB' })

        // setLastTestTime(relativeTime.from(lastTestTime));
        setLastTester(lastEvent.testSubmittedBy);
        setLastTestTime(lastTestTime.toLocaleString());
    }

    useEffect(() => {
        if (siteEvents.length < 1)
            return;

        calculateTestNumbers();
        findLastTests();
    }, [location, siteEvents, siteDevices]);

    if (isBundleLoading) return (<Spinner size='large'/>);

    return (
        <Container
            header={
                <Header variant='h2'>
                    {bundle.getMessage('overview')}
                </Header>
            }
        >
            <ColumnLayout columns={3} variant='text-grid'>
                <SpaceBetween size='l'>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('site')}</Box>
                        <div>{`${siteName!.toUpperCase()}`}</div>
                    </div>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('total-devices')}</Box>
                        <div>
                            {`${appEventState.loadedProdDevices.get() + appEventState.loadedUCDevices.get()} 
                            (${appEventState.loadedProdDevices.get()} prod | ${appEventState.loadedUCDevices.get()} uc)`}
                        </div>
                    </div>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('total-tests')}</Box>
                        <div>{siteEvents.length}</div>
                    </div>
                </SpaceBetween>
                <SpaceBetween size='l'>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('tests-passed')}</Box>
                        <div>{passedEventsCount}</div>
                    </div>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('tests-failed')}</Box>
                        <div>{failedEventsCount}</div>
                    </div>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('percentage-passed')}</Box>
                        <div>{passedEventsPercent}</div>
                    </div>
                </SpaceBetween>
                <SpaceBetween size='l'>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('last-test')}</Box>
                        <div>{lastTestTime}</div>
                    </div>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('last-tested-by')}</Box>
                        <div>
                            {lastTestUser == '-' ? '-' : (
                                <Link external href={`https://phonetool.amazon.com/users/${lastTestUser}`}>
                                    {lastTestUser}@
                                </Link>
                            )}
                        </div>
                    </div>
                </SpaceBetween>
            </ColumnLayout>
        </Container>
    );
}