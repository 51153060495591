import React, { useEffect, useReducer, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
// import LanguagePicker from './components/LanguagePicker';
import logo from 'src/images/logo.png';
import { Auth } from 'aws-amplify';
import { useHookstate } from '@hookstate/core';
import { appBaseState } from 'src/stores/app';
import { LoadingReducer, SERVER_REQUEST_END, SERVER_REQUEST_START } from 'src/hooks/loading';
import { KatSpinner } from '@amzn/katal-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopNav from './components/TopNav';
import SiteNavigation from './components/SiteSelection';
import SitePage from './components/SitePage';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import IsItDown from './components/IsItDown';
import { useBundle } from '@amzn/react-arb-tools';

function App() {
    const appState = useHookstate(appBaseState);
    const [{loading}, dispatch] = useReducer(LoadingReducer, {loading: true});
    const [siteSelectVisible, setSiteSelectVisible] = useState(false);
    const [bundle, isBundleLoading] = useBundle('components.App');

    let userGroups = [];

    const setUserData = (cognitoUserData: any) => {
        const idToken = cognitoUserData = cognitoUserData.getSignInUserSession().getIdToken();
        const userId = idToken.payload['identities'][0].userId;

        try {
            userGroups = idToken.payload['custom:posix'].split(',');
        } catch {
            userGroups = [];
        }
        
        appState.user.username.set(userId);
        appState.user.userGroups.set(userGroups);
    }

    // set app loading state and authenticate
    useEffect(() => {
        dispatch({type: SERVER_REQUEST_START});

        Auth.currentAuthenticatedUser().then((cognitoUserData) => {
            setUserData(cognitoUserData);
            dispatch({type: SERVER_REQUEST_END});
        }).catch((err) => {
            console.log('No user data found, please sign in..');
            Auth.federatedSignIn({
                customProvider: 'AmazonFederate'
            }).catch((err) => {
                console.log('Error trying to sign user in via Federate..', err);
            })
        });
    }, []);

    return (
        <div>
            {loading || isBundleLoading ? (
                <KatSpinner size='large' />
            ) : 
                (
                    <div>
                        {(appState.user.username.value) ? (
                            <div>
                                <IsItDown />
                                <Routes>
                                    <Route index element={<SiteNavigation isVisible={siteSelectVisible} setVisible={setSiteSelectVisible} />} />
                                    <Route path="/site/:siteName" element={
                                        <div>
                                            <TopNav
                                                setSiteSelectVisible={setSiteSelectVisible}
                                                appState={appState}
                                            />
                                            {appState.loadedSites.value.length > 0 ? <SitePage /> : (
                                                <Box textAlign='center'>
                                                    <StatusIndicator type='loading'>
                                                        {bundle.getMessage('waiting-for-site-data')}
                                                    </StatusIndicator>
                                                </Box>
                                            )}
                                            {/* <SitePage /> */}
                                            <SiteNavigation isVisible={siteSelectVisible} setVisible={setSiteSelectVisible} />
                                        </div>
                                    } />
                                    <Route path="*" element={<Navigate to="/" />} />
                                </Routes>
                            </div>
                        ) : (
                            <div>
                                <Box
                                    margin={{ top: "s", left: "s"}}
                                    color="inherit"
                                    display="block"
                                    fontSize="heading-l"
                                >
                                    You do not have access to this page. Please contact
                                    <Link external href="https://w.amazon.com/index.php/SIDE#HContactus"> SIDE Support</Link> if you have
                                    any questions.
                                </Box>
                            </div>
                        )}
                    </div>
                )
            }
        </div>
    )
}

export default App;