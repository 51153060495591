import React from 'react';

/**
 * Just temporarily holding some default options for our device
 * table here. Rest of preference components and functionality will
 * be moved around/added once user preferences are moved to DDB and 
 * rest of user settings are implemented for Greenlight.
 */

export const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 Devices' },
    { value: 20, label: '20 Devices' },
    { value: 30, label: '30 Devices' },
    { value: 50, label: '50 Devices' },
    { value: 75, label: '75 Devices' },
    { value: 100, label: '100 Devices' }
];

export const DEFAULT_PREFERENCES = {
    pageSize: 30,
    wrapLines: false,
    stripedRows: true
}

export const EDITABLE_PREFERENCES = {
    pageSize: 30,
    wrapLines: false,
    stripedRows: true
}