import { EventsTableItem } from "src/interfaces";

export const exportDeviceCSV = (siteName: string, deviceList: EventsTableItem[], sectionName: string | null = null) => {
    // Set header for file name
    const sectionHeader = sectionName ?? 'AllDevices';

    if (deviceList.length < 1)
        throw new Error("Unable to export, no devices provided");
        
    // Filter only for devices with all tests successful
    const successfulDevices = deviceList.filter(device => device.tests_successful);

    let csvContent = `parentID,childID,subchildID,deviceName,deviceID\n`;

    // Add to CSV content
    successfulDevices.forEach(device => {
        csvContent += `${device.device_id.split('_')},${device.device_name},${device.device_id}\n`;
    });

    const csvBlob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement('a');

    a.setAttribute('href', url);
    a.setAttribute('download', `GreenlightExport-${siteName}-${sectionHeader}.csv`);
    a.click();
}