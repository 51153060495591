import { SelectProps } from "@amzn/awsui-components-react";

export const APP_BASE_TITLE = "Greenlight (Site Testing Tool)";
export const DEFAULT_USER_LOCALE = navigator.language || 'en-US';

export const NAV_LINKS = {
    GREENLIGHT_PUBLIC_WIKI: "https://w.amazon.com/bin/view/SIDE/Greenlight/",
    GREENLIGHT_FEEDBACK_URL: "https://w.amazon.com/bin/view/AccSys/inner/Greenlight/#HFeatureRequests",
    GREENLIGHT_BUG_SIM: "https://t.corp.amazon.com/create/templates/90262f72-4e03-4368-b7a2-f61afca6c675",
    GUARDIAN_HUB_URL: "https://guardian.gso.amazon.dev/"
}

export const ONGUARD_COMMAND_KEYPAD_IDS = [144, 151, 152, 153];

interface ILanguage {
    id: string;
    text: string;
}

export const LANGUAGE_PREFERENCES: SelectProps.Options = [
    { value: "en-US", label: "English" },
    { value: "fr-FR", label: "French" },
    { value: "de-DE", label: "German" },
    { value: "es-ES", label: "Spanish" },
    { value: "pt-PT", label: "Portuguese" },
    { value: "it-IT", label: "Italian" },
    { value: "hi-IN", label: "Hindi" },
    { value: "ja-JP", label: "Japanese" },
    { value: "ar-AE", label: "Arabic (U.A.E.)" },
    { value: "ar-SA", label: "Arabic (Saudi Arabia)" },
    { value: "cs-CZ", label: "Czech (Czech Republic)" },
    { value: "en-AU", label: "English (Australia)" },
    { value: "en-CA", label: "English (Canada)" },
    { value: "en-GB", label: "English (United Kingdom)" },
    { value: "en-SG", label: "English (Singapore)" },
    { value: "es-MX", label: "Spanish (Mexico)" },
    { value: "fr-CA", label: "French (Canada)" },
    { value: "nl-NL", label: "Dutch (Netherlands)" },
    { value: "pl-PL", label: "Polish (Poland)" },
    { value: "sk-SK", label: "Slovak (Slovakia)" },
    { value: "tr-TR", label: "Turkish (Turkey)" },
    { value: "zh-CN", label: "Chinese (S)" },
    { value: "zh-TW", label: "Chinese (T)" }
];