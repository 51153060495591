import React, { ReactNode, useEffect, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import { 
    Alert, 
    ButtonDropdown, 
    CollectionPreferences, 
    CollectionPreferencesProps, 
    FlashbarProps, 
    FormField, 
    Modal, 
    Multiselect, 
    MultiselectProps, 
    Pagination, 
    RadioGroup, 
    SpaceBetween, 
    Table, 
    TableProps, 
    TokenGroup 
} from '@amzn/awsui-components-react';
import TextFilter, { TextFilterProps } from '@amzn/awsui-components-react/polaris/text-filter';
import PropertyFilter, { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import Box from '@amzn/awsui-components-react/polaris/box';
import { EventsTableItem, PACSDevice } from 'src/interfaces';
import { useHookstate } from '@hookstate/core';
import { BulkModal } from './BulkModal';
import { NotificationActionType, useNotifications } from 'src/hooks/notifications';
import { useParams } from 'react-router-dom';
import { exportDeviceCSV } from 'src/utils/exportDevices';
import { DEFAULT_PREFERENCES, PAGE_SIZE_OPTIONS } from './DeviceTablePreferences';
import { RelatedTable, useTreeCollection } from "@amzn/polaris-related-table";
import styles from "./deviceTable.module.scss";

interface EmptyTableStateProps {
    title: string;
    subtitle: string;
    action?: ReactNode;
}

function EmptyTableState({ title, subtitle, action }: EmptyTableStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export function DeviceTable({tableItems, tableColumns, sectionHeader, tableVariant}: {
    tableItems: any[],
    tableColumns: any[],
    sectionHeader: string,
    tableVariant: TableProps.Variant
}) {
    const [bulkTestVisible, setBulkTestVisible] = useState(false);
    const [bulkCancelVisible, setBulkCancelVisible] = useState(false);
    const [bulkModalVisible, setBulkModalvisible] = useState(false);
    const [bulkModalAction, setBulkModalAction] = useState('');
    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>(DEFAULT_PREFERENCES);

    const showBulkModal = (action: string) => {
        setBulkModalAction(action);
        setBulkTestVisible(true);
    }

    const { dispatch } = useNotifications();
    let { siteName } = useParams();

    const { expandNode, items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useTreeCollection(
        tableItems || [],
        {
            columnDefinitions: tableColumns,
            parentKeyPropertyName: "linked_input_id",
            keyPropertyName: "device_id",
            expandedPropertyName: "expanded",
            propertyFiltering: {
                filteringProperties: [
                    {
                        propertyLabel: "All Tests Passed",
                        key: 'tests_successful',
                        groupValuesLabel: 'Test Result Values',
                        operators: ['=', '!=']
                    },
                    {
                        propertyLabel: "Parent Device Name",
                        key: 'parent_device_name',
                        groupValuesLabel: 'Parent Device Name Values',
                        operators: [':', '!:', '=', '!=']
                    },
                    {
                        propertyLabel: "Device Name",
                        key: 'device_name',
                        groupValuesLabel: 'Device Name Values',
                        operators: [':', '!:', '=', '!=']
                    },
                    {
                        propertyLabel: "Device ID",
                        key: 'device_id',
                        groupValuesLabel: 'Device ID Values',
                        operators: [':', '!:', '=', '!=']
                    },
                    {
                        propertyLabel: "Linked Device ID",
                        key: 'linked_device_id',
                        groupValuesLabel: 'Linked Device ID Values',
                        operators: [':', '!:', '=', '!=']
                    },
                    // Currently the only way of filtering a list; waiting for https://issues.amazon.com/issues/AWSUI-18334
                    {
                        propertyLabel: "Device Testers",
                        key: 'testers',
                        groupValuesLabel: 'Device Testers',
                        operators: [':', '!:']
                    },
                ],
                empty: (
                    <EmptyTableState
                        title="No devices"
                        subtitle="No devices to display."
                    />
                ),
                noMatch: (
                    <EmptyTableState
                        title="No matches"
                        subtitle="We can't find a match."
                    />
                ),
                defaultQuery: {
                    operation: "and",
                    tokens: [
                        {operator: ":", propertyKey: "device_name", value: "uc_"}
                    ]
                }
            },
            pagination: {
                pageSize: preferences.pageSize
            },
            sorting: {},
            selection: {
                keepSelection: true,
                trackBy: "device_id"
            }
        }
    );

    // ignore ref since RelatedTable does not support it
    const {ref, ...fcCollectionProps} = collectionProps;
    const selectedItems = collectionProps.selectedItems!;
    const isOnlyOneSelected = selectedItems.length === 1;

    // Export devices for a specific section
    const exportSectionDevices = () => {
        // Only send uc_ devices. tableItems will only have items for the section table
        const ucItems: EventsTableItem[] = tableItems.filter(device => device.device_name && device.device_name.startsWith('uc_'));

        if (ucItems.length < 1) {
            let message: FlashbarProps.MessageDefinition = {
                type: 'error',
                header: 'No items to export',
                content: 'There are no available devices to export for this section. Only uc_ devices can be exported.'
            }

            dispatch({
                type: NotificationActionType.ADD_NOTIFICATION,
                message: message
            });

            return;
        }

        exportDeviceCSV(siteName!, ucItems, sectionHeader);
    }


    return (
        <div className={styles.deviceTable}>
            <RelatedTable
                {...fcCollectionProps}
                expandChildren={expandNode}
                expandColumnPosition={2}
                columnDefinitions={tableColumns}
                items={items}
                trackBy='device_id'
                stripedRows={preferences.stripedRows}
                wrapLines={preferences.wrapLines}
                sortingDisabled={false}
                resizableColumns={true}
                filter={
                    <PropertyFilter
                        i18nStrings={{
                            filteringAriaLabel: 'your choice',
                            dismissAriaLabel: 'Dismiss',
                            filteringPlaceholder: 'Search',
                            groupValuesText: 'Values',
                            groupPropertiesText: 'Properties',
                            operatorsText: 'Operators',
                            operationAndText: 'and',
                            operationOrText: 'or',
                            operatorLessText: 'Less than',
                            operatorLessOrEqualText: 'Less than or equal',
                            operatorGreaterText: 'Greater than',
                            operatorGreaterOrEqualText: 'Greater than or equal',
                            operatorContainsText: 'Contains',
                            operatorDoesNotContainText: 'Does not contain',
                            operatorEqualsText: 'Equals',
                            operatorDoesNotEqualText: 'Does not equal',
                            editTokenHeader: 'Edit filter',
                            propertyText: 'Property',
                            operatorText: 'Operator',
                            valueText: 'Value',
                            cancelActionText: 'Cancel',
                            applyActionText: 'Apply',
                            allPropertiesLabel: 'All properties',
                            tokenLimitShowMore: 'Show more',
                            tokenLimitShowFewer: 'Show fewer',
                            clearFiltersText: 'Clear filters',
                            removeTokenButtonAriaLabel: () => 'Remove token',
                            enteredTextLabel: text => `Use: "${text}"`,
                        }}
                        countText={`${filteredItemsCount} matches`}
                        {...propertyFilterProps}
                    />
                }
                header={
                    <Header
                        variant='h3'
                        counter={
                            tableItems && (
                                selectedItems.length ? `${selectedItems!.length} / ${tableItems.length} devices selected` : `${tableItems.length} devices`
                            )
                        }
                        actions={
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button iconName='download' disabled={false} onClick={() => exportSectionDevices()}>
                                    Export successful devices
                                </Button>
                                <ButtonDropdown
                                    variant='primary'
                                    items={[
                                        {id: 'test', text: selectedItems.length ? `Test selected (${selectedItems.length})` : `Test all`, disabled: false},
                                        {id: 'cancel', text: selectedItems.length ? `Cancel selected (${selectedItems.length})` : `Cancel all`, disabled: false}
                                    ]}
                                    onItemClick={(ev) => showBulkModal(ev.detail.id)}
                                >
                                    {selectedItems.length ? `Test or cancel selected` : `Test or cancel all`}
                                </ButtonDropdown>
                            </SpaceBetween>
                        }
                    />
                }
                pagination={
                    <Pagination {...paginationProps} />
                }
                variant={tableVariant}
                selectionType='multi'
                empty={
                    <EmptyTableState
                        title="No devices"
                        subtitle="There are no devices to display."
                    />
                }
                preferences={
                    <CollectionPreferences 
                        title="Table Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        preferences={preferences}
                        onConfirm={({ detail }) => setPreferences(detail)}
                        pageSizePreference={{
                            title: 'Page size',
                            options: PAGE_SIZE_OPTIONS
                        }}
                        wrapLinesPreference={{
                            label: 'Wrap lines',
                            description: 'Select to wrap lines in the table'
                        }}
                        stripedRowsPreference={{
                            label: 'Stripe rows',
                            description: 'Select to highlight every other row in the table'
                        }}
                    />
                }
            />

            {bulkTestVisible ? (
                <BulkModal deviceItems={selectedItems.length ? selectedItems : (filteredItemsCount && filteredItemsCount == tableItems.length) ? tableItems : items} eventColumns={tableColumns} isVisible={bulkTestVisible} setVisible={setBulkTestVisible} action={bulkModalAction}/>
            ) : null}
        </div>
    );
}